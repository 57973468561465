import { SignInOutput } from '@aws-amplify/auth';
import { SignInState } from '../types';
import { NavigateFunction } from 'react-router-dom';

let credentialsHolder: { username?: string | undefined; password?: string | undefined };

export async function signInOutputHandler(
  signInOutput: SignInOutput,
  navigateToFunction: NavigateFunction,
  credentials?: {
    username?: string;
    password?: string;
  },
  locationState?: {
    location?: string;
    search?: string;
  }
) {
  if (credentials) {
    credentialsHolder = credentials;
  }
  const { nextStep } = signInOutput;

  if (nextStep.signInStep === SignInState.DONE) {
    console.log(nextStep);

    const searchParams = new URLSearchParams(locationState?.search);
    const redirectUri = searchParams.get('redirect_uri');

    if (redirectUri) {
      try {
        const resp = await fetch(`https://auth.${window.location.host}/auth_finalize${locationState?.search}`, {
          method: 'POST',
          body: JSON.stringify({
            payload: window.btoa(
              JSON.stringify({ username: credentialsHolder?.username, password: credentialsHolder?.password })
            )
          }),
          redirect: 'follow'
        });

        const body = await resp.json();

        console.log(body);

        if (body.redirect) {
          console.log('Redirecting...');
          window.location.href = body.redirect;

          return;
        }
      } catch (someError) {
        console.log(someError);
      }
    }

    if (!redirectUri?.startsWith('http')) {
      navigateToFunction(redirectUri ? redirectUri : locationState?.location ? locationState.location : '/account', {
        replace: true
      });
    }
  }

  if (nextStep.signInStep === SignInState.CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED) {
    navigateToFunction('/auth/sign_in_with_new_password');
  }

  if (nextStep.signInStep === SignInState.CONFIRM_SIGN_UP) {
    console.log('ddd');
  }

  if (nextStep.signInStep === SignInState.CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE) {
    navigateToFunction(
      locationState?.search ? `/auth/sign_in_code_confirm${locationState.search}` : '/auth/sign_in_code_confirm',
      {
        state: JSON.stringify({
          userEmail: nextStep.additionalInfo?.email
        })
      }
    );
  }
}
