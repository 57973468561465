import React from 'react';
import { CompanyOrderedLicenseDTO } from '../../../CompanyOrderedLicenses/CompanyOrderedLicenses';
import { useProductData } from '../../../../hooks/useProductData';
import BorderedContainer from '../../../../components/Containers/BorderedContainer';
import logo from '../../../../assets/mint_medical_logo.svg';

export interface OrganizationLicenseProps {
  license: CompanyOrderedLicenseDTO;
}

export function OrganizationLicense(props: OrganizationLicenseProps) {
  const { productData, getTierName, getTierPrice, calculateYearlyPriceForGivenSeats } = useProductData({
    productId: props.license.productId,
    lang: 'en'
  });

  return (
    <div className="mb-2 mt-2">
      <BorderedContainer>
        <div className="">
          <div className="flex flex-col justify-between self-start md:flex-row">
            <img
              className="w-3/4 self-center py-4 md:w-1/6 md:self-start"
              src={logo}></img>
            <div className={'my-auto flex w-full flex-row justify-between self-start'}>
              <span className="pl-0 text-base font-semibold text-mint-medical-grey md:pl-4">
                {productData?.name ? productData.name : ''} |{' '}
                {props.license.seats ? getTierName(props.license.seats) : ''}
              </span>
              <span className="text-base font-semibold">
                <>&euro;</>
                {getTierPrice(props.license.seats)} per user/month
              </span>
            </div>
          </div>
          <div className="flex flex-row justify-between self-start">
            <span className=" my-auto flex flex-col text-wrap pr-1 text-base font-semibold text-mint-medical-grey md:flex-row">
              Number of users for your plan
            </span>
            <input
              id="seats"
              disabled={true}
              className="w-12 rounded-md border-2 border-mint-medical-green-lighter p-2 text-center focus:border-mint-medical-green focus:outline-none md:w-24"
              value={props.license.seats}
              type="number"></input>
          </div>
          <div className="">
            <div className="flex justify-between">
              <span className="my-auto text-base font-semibold text-mint-medical-grey">Total</span>
              <span className="text-base font-semibold">
                {calculateYearlyPriceForGivenSeats(props.license.seats)} billed annually
              </span>
            </div>
          </div>
          <div className="flex flex-row space-x-1">
            <span>Subscription status:</span>
            <span
              className={
                props.license.status?.toLowerCase() === 'active' ? ' text-mint-medical-green' : ' text-red-500'
              }>
              {props.license.status.toUpperCase()}
            </span>
          </div>
          <span>Purchase Date: {new Date(props.license.start_date! * 1000).toLocaleDateString()}</span>
          <div>
            <span className="text-[#D81818]">
              {props.license.cancel_at ? (
                props.license.cancel_at * 1000 > Date.now() ? (
                  <>Ends on {new Date(props.license.cancel_at * 1000).toLocaleDateString()}</>
                ) : (
                  <>Ended on {new Date(props.license.cancel_at * 1000).toLocaleDateString()}</>
                )
              ) : (
                <>Auto renews on {new Date(props.license.period_end_date! * 1000).toLocaleDateString()}</>
              )}
            </span>
          </div>
        </div>

        <div className="text-md py-4 text-justify font-semibold text-mint-medical-grey">{productData.description}</div>
      </BorderedContainer>
    </div>
  );
}
