import React, { useEffect, useState } from 'react';
import { Header, HeaderWrapper } from '../../../../components/Header';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Input } from '../../../../components/Input';
import { DataLoader, LoadingStatus } from '../../../../types/DataLoader';
import { FullScreenSpinner } from '../../../OrderForm/components/OrderForm/FullScreenSpinner';
import { get, put } from '../../../../libs/Requests';
import { CompanyActionUpdater } from '../../components/CompanyStatusUpdater/CompanyStatusUpdater';
import { EStatus } from '../../../../types/Status';
import { CompanyDomains } from '../../../organization_admin_account/components/CompanyDomains/CompanyDomains';
import { OrganizationEditableDataForm } from '../../../OrganizationEditableDataForm/OrganizationEditableDataForm';
import { ConfirmationActions, ConfirmationModal } from '../../components/CompanyStatusUpdater/ConfirmationModal';
import { OrganizationLicenses } from '../../../organization_licenses/components/OrganizationLicenses/OrganizationLicenses';
import { CompanyOrderedLicenses } from '../../../CompanyOrderedLicenses/CompanyOrderedLicenses';

interface Company {
  status: string;
  requiredAction: Foo;
  id: string;
  name: string;
}

interface CompanyLicenseDTO {
  status?: string;
  seats?: number | undefined;
  users?: string;
  id: string;
}

interface CompanyDomainDTO {
  name: string;
  status: EStatus;
}

interface CompanyDTO {
  status: EStatus | undefined;
  id: string;
  name: string;
  seats: number;
  licenses: CompanyLicenseDTO[];
  domains: CompanyDomainDTO[];
  manager: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
  };
}

interface Bar {
  status: string;
  domainName: string;
}

interface Foo {
  accountApprovementRequired: boolean;
  domainApprovementRequired: Bar[];
}

export interface CompanyDataLoader extends DataLoader {
  data?: CompanyDTO;
}

enum CompanyStatus {
  Approved = 'Approved',
  Denied = 'Denied'
}

interface ConfirmationData {
  action: string;
  yesLabel: string;
  yesAction: () => void;
}

export function Company() {
  const [confirmationData, setConfirmationData] = useState<ConfirmationData | undefined>();
  const [companyName, setCompanyName] = useState('');

  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const location = useLocation();
  const navigate = useNavigate();

  const [fetchData, setFetchData] = useState(true);
  const companyId = location.pathname.split('/company/').pop()?.replaceAll('/', '');

  const [companyDataLoader, setCompanyDataLoader] = useState<CompanyDataLoader>({
    loadingStatus: LoadingStatus.Loading,
    data: {
      status: undefined,
      domains: [],
      id: '',
      name: '',
      seats: 0,
      manager: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: ''
      },
      licenses: []
    }
  });

  async function handleStatusClick(status: string) {
    try {
      await put(
        `/admin/company/${companyId}/status`,
        JSON.stringify({
          status: status
        })
      );

      setConfirmationData(undefined);
      setFetchData(true);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    if (authStatus != 'authenticated') {
      return;
    }

    const abortController = new AbortController();
    const signal = abortController.signal;

    setCompanyDataLoader({
      ...companyDataLoader,
      loadingStatus: LoadingStatus.Loading
    });

    get(`/admin/company/${companyId}/`, { signal })
      .then(async (companyResponse) => {
        if (companyResponse.status !== 200) {
          throw 'Error!';
        }

        const data = (await companyResponse.json()) as CompanyDTO;

        setCompanyName(data.name);

        setCompanyDataLoader({
          ...companyDataLoader,
          data: data as CompanyDTO,
          loadingStatus: LoadingStatus.Succeed
        });
      })
      .catch((error) => {
        setCompanyDataLoader({
          ...companyDataLoader,
          error: error.name === 'AbortError' ? undefined : 'Oops, some problems occurred during loading...',
          loadingStatus: LoadingStatus.Failed
        });
      });

    setFetchData(false);

    return () => {
      abortController.abort();
    };
  }, [authStatus, fetchData]);

  function fireConfirmationModal(action: CompanyStatus) {
    setConfirmationData({
      action: action,
      yesLabel: action === CompanyStatus.Approved ? 'Yes, Approve' : 'Yes, Deny',
      yesAction: async () => {
        await handleStatusClick(action);
      }
    });
  }

  function handleBack() {
    navigate(-1);
  }

  if (authStatus === 'configuring') return <FullScreenSpinner></FullScreenSpinner>;

  if (authStatus !== 'authenticated') {
    return (
      <Navigate
        to="/auth/sign_in"
        state={{ location }}
        replace></Navigate>
    );
  }

  return (
    <>
      {companyDataLoader.loadingStatus === LoadingStatus.Loading && <FullScreenSpinner></FullScreenSpinner>}

      <HeaderWrapper>
        <div className="my-auto w-1/5 md:w-1/3">
          <div className="flex flex-row">
            <button
              onClick={handleBack}
              className=" flex flex-row font-bold text-mint-medical-green">
              <svg
                className="my-auto fill-mint-medical-green"
                width="15"
                height="24"
                viewBox="0 0 15 24"
                fill="none">
                <path d="M5.59373 11.9995L14.1797 20.7651C14.5625 21.1558 14.5586 21.7808 14.1719 22.1714L12.6367 23.7065C12.2422 24.0972 11.6094 24.0972 11.2187 23.7026L0.292953 12.7065C0.0976391 12.5112 -1.61396e-05 12.2573 -1.61171e-05 11.9995C-1.60946e-05 11.7417 0.0976392 11.4878 0.292953 11.2925L11.2187 0.296385C11.6094 -0.0981467 12.2422 -0.0981466 12.6367 0.292478L14.1719 1.82763C14.5586 2.21826 14.5625 2.84326 14.1797 3.23388L5.59373 11.9995Z" />
              </svg>
              <span className="hidden md:inline">Go Back to Organization Details</span>
            </button>
          </div>
        </div>
        <Header>{companyName}</Header>

        <div className="my-auto flex w-1/5 justify-end md:w-1/3">
          {confirmationData && (
            <ConfirmationModal
              action={
                confirmationData.action === CompanyStatus.Approved
                  ? ConfirmationActions.Approve
                  : ConfirmationActions.Deny
              }
              title={'Confirmation'}
              visible={!!confirmationData}
              yesLabel={confirmationData.yesLabel}
              closeModal={() => {
                setConfirmationData(undefined);
              }}
              yesMethod={confirmationData.yesAction}>
              Are you sure you want to {confirmationData.action === CompanyStatus.Approved ? 'approve' : 'deny'} company{' '}
              <span className=" font-semibold">{companyName}</span>?
            </ConfirmationModal>
          )}
          {companyDataLoader.loadingStatus === LoadingStatus.Succeed &&
            companyDataLoader.data!.status !== 'Pending' && (
              <select
                value={companyDataLoader.data?.status}
                onChange={(event) => {
                  if (event.target.value != companyDataLoader.data?.status) {
                    fireConfirmationModal(
                      event.target.value === CompanyStatus.Approved ? CompanyStatus.Approved : CompanyStatus.Denied
                    );
                  }
                }}
                className={
                  companyDataLoader.data?.status === EStatus.Approved
                    ? 'cursor-pointer text-wrap pl-2 text-center text-end font-semibold text-mint-medical-green outline-none disabled:appearance-none md:inline'
                    : 'cursor-pointer text-wrap pl-2 text-center text-end font-semibold text-red-500 outline-none disabled:appearance-none md:inline'
                }>
                <option value={EStatus.Approved}>Approved</option>
                <option value={EStatus.Denied}>Denied</option>
              </select>
            )}
          {companyDataLoader.data!.status === 'Pending' && (
            <CompanyActionUpdater
              companyId={companyId!}
              onStatusChange={() => {
                setFetchData(true);
              }}
              companyName={companyDataLoader.data!.name}></CompanyActionUpdater>
          )}
        </div>
      </HeaderWrapper>

      <div className="flex w-full flex-col">
        <div className="flex justify-between py-4">
          <span className="font-semibold">Account Information</span>
        </div>

        <div className="w-full md:w-1/2 md:pr-2">
          <Input
            type="email"
            label="Email"
            value={companyDataLoader.data?.manager.email}
            disabled={true}></Input>
        </div>

        <OrganizationEditableDataForm
          dataEndpoint={`/admin/company/${companyId}/editable_data`}
          onCompanyNameChange={(newCompanyName: string) => {
            setCompanyName(newCompanyName);
          }}></OrganizationEditableDataForm>

        <CompanyDomains
          getEndpoint={`/admin/company/${companyId}/domains`}
          postEndpoint={`/admin/company/${companyId}/domains`}></CompanyDomains>

        <CompanyOrderedLicenses companyId={companyId!}></CompanyOrderedLicenses>

        {companyDataLoader.data?.status !== EStatus.Pending && (
          <OrganizationLicenses
            companyId={companyId!}
            editable={companyDataLoader.data?.status === EStatus.Approved}></OrganizationLicenses>
        )}
      </div>
    </>
  );
}
