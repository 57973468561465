import React from 'react';
import { HeadMetadata } from '../../../../components/HeadMetadata';
import { Header, HeaderWrapper } from '../../../../components/Header';
import { Description } from '../../../../components/Description';

const SEOMetadata = {
  Title: 'Mint Medical Subscription Store | Not Found'
};

export function NotFoundPage() {
  return (
    <>
      <HeadMetadata title={SEOMetadata.Title}></HeadMetadata>

      <HeaderWrapper>
        <Header>Not Found :(</Header>
      </HeaderWrapper>

      <Description>You shouldn&apos;t be here.</Description>
    </>
  );
}
