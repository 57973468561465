import React, { useState } from 'react';
import { Modal } from '../../../../components/Modal/Modal';
import { Input } from '../../../../components/Input';
import { post } from '../../../../libs/Requests';

export interface ContactSalesTeamFormProps {
  onCloseClick: () => void;
  onSubmissionSent: () => void;
}

interface ContactSalesTeamFormDTO {
  first_name: string;
  last_name: string;
  business_name: string;
  phone_number: string;
  email: string;
  number_of_seats: number;
  message?: string;
}

export function ContactSalesTeamForm(props: ContactSalesTeamFormProps) {
  const [formSent, setFormSent] = useState(false);
  const [submissionError, setSubmissionError] = useState('');
  const [contactSalesTeamFormFields, setContactSalesTeamFormFields] = useState<ContactSalesTeamFormDTO>({
    first_name: '',
    last_name: '',
    business_name: '',
    phone_number: '',
    email: '',
    number_of_seats: 0,
    message: ''
  });

  function handleChange(name: string, value: string | number) {
    setContactSalesTeamFormFields({ ...contactSalesTeamFormFields, [name]: value });
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setSubmissionError('');

    try {
      const resp = await post('/contact_sales_team', JSON.stringify(contactSalesTeamFormFields));

      if (resp.status === 200) {
        setFormSent(true);

        props.onSubmissionSent();

        return;
      } else {
        const errorText = 'Some error occured during submission :( Try again.';

        setSubmissionError(errorText);
      }
    } catch (error) {
      const errorText = 'Some error occured during submission :( Try again.';

      setSubmissionError(errorText);
      console.log(error);
    }
  }

  return (
    <Modal
      closeModal={props.onCloseClick}
      visible={true}>
      {!formSent && (
        <div className="h-full w-full space-y-8">
          <div className="text-center">
            <div className="text-lg font-bold">Contact us</div>
          </div>
          <div className="text-center">A team member will be in contact with you within 48 hours.</div>
          <form
            className="h-full w-full"
            onSubmit={handleSubmit}>
            <div>
              <Input
                id="first_name"
                name="first_name"
                label="First Name"
                required
                onChange={(event) => {
                  handleChange(event.target.name, event.target.value);
                }}
                type="text"></Input>
              <Input
                id="last_name"
                name="last_name"
                label="Last Name"
                onChange={(event) => {
                  handleChange(event.target.name, event.target.value);
                }}
                type="text"></Input>
            </div>
            <div>
              <Input
                id="business_name"
                name="business_name"
                label="Business Name"
                required
                onChange={(event) => {
                  handleChange(event.target.name, event.target.value);
                }}
                type="text"></Input>
              <Input
                id="phone_number"
                name="phone_number"
                label="Business Phone Number"
                required
                onChange={(event) => {
                  handleChange(event.target.name, event.target.value);
                }}
                type="tel"></Input>
            </div>
            <div>
              <Input
                id="email"
                name="email"
                label="Email Address"
                required
                onChange={(event) => {
                  handleChange(event.target.name, event.target.value);
                }}
                type="email"></Input>
              <Input
                id="number_of_seats"
                name="number_of_seats"
                min={0}
                label="Number of Users for Your Custom Plan"
                required
                onChange={(event) => {
                  handleChange(event.target.name, parseInt(event!.target!.value!));
                }}
                type="number"></Input>
            </div>

            <div>
              <label
                className="text-xs font-semibold text-[#787878]"
                htmlFor="message">
                How can we help you?
              </label>
              <textarea
                id="message"
                name="message"
                onChange={(event) => {
                  handleChange(event.target.name, event.target.value);
                }}
                className="h-32 w-full rounded-md border-2 border-[#D2D2D2] text-black outline-none disabled:bg-[#F0F0F0] disabled:text-black"></textarea>
            </div>

            <div className="flex min-h-8 w-full">
              <span className={submissionError ? 'visible mx-auto self-center text-center text-red-500' : ' invisible'}>
                {submissionError}
              </span>
            </div>

            <div className="flex py-2">
              <Input
                type="submit"
                value="Submit"></Input>
            </div>
          </form>
        </div>
      )}
      {formSent && (
        <div className="h-full w-full space-y-8">
          <div className="text-center">
            <div className="text-lg font-bold">Contact us</div>
          </div>
          <div className="mx-auto h-1/2 w-1/2">
            <svg
              className="h-full w-full fill-mint-medical-green"
              id="Capa_1"
              viewBox="0 0 50 50"
              xmlSpace="preserve">
              <circle
                cx="25"
                cy="25"
                r="25"
              />
              <polyline
                className="fill-none stroke-white stroke-2"
                points="38,15 22,33 12,25"
              />
            </svg>
          </div>
          <div className="text-center text-2xl  font-bold">Thank You!</div>
          <div className="mx-auto w-8/12">
            Your submission has been sent. A team member will be in contact with you within 48 hours.
          </div>
        </div>
      )}
    </Modal>
  );
}
