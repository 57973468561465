import React from 'react';
import { RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';

import { Account } from '../pages/Account';
import { MainLayout } from '../layout/MainLayout';
import { SignUpPage } from '../pages/SignUp';
import { Company } from '../features/mint_admin_account/pages/Company/Company';

import authRoutes from '../features/auth/routes';
import landingRoutes from '../features/landing/routes';
import documentsRoutes from '../features/documents/routes';
import notFoundRoutes from '../features/not_found/routes';

const AppRoutes = () => {
  const routes: RouteObject[] = [
    {
      path: '/',
      element: <MainLayout></MainLayout>,
      children: [
        {
          path: 'order',
          element: <SignUpPage></SignUpPage>
        },
        {
          path: 'account',
          element: <Account></Account>
        },
        {
          path: 'account/company/*',
          element: <Company></Company>
        }
      ]
    }
  ];

  const router = createBrowserRouter([
    ...landingRoutes,
    ...documentsRoutes,
    ...routes,
    ...authRoutes,
    ...notFoundRoutes
  ]);

  return <RouterProvider router={router} />;
};

export default AppRoutes;
