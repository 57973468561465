import React from 'react';
import { RouteObject } from 'react-router-dom';
import { MainLayout } from '../../../layout/MainLayout';
import { DocumentPage } from '../pages/Document';

const documentsRoutes: RouteObject[] = [
  {
    path: '/',
    element: <MainLayout></MainLayout>,
    children: [
      {
        path: 'document/*',
        element: <DocumentPage></DocumentPage>
      }
    ]
  }
];

export default documentsRoutes;
