import { getFileUrl } from '../api/getFileUrl';

export async function handleDownload(downloadRef: React.RefObject<HTMLAnchorElement>, fileName: string) {
  let fileUrl;

  if (fileName.startsWith('http')) {
    fileUrl = fileName;
  } else {
    fileUrl = await getFileUrl(fileName);
  }

  const _ = new URL(fileUrl);

  window.location.href = _.toString();
}
