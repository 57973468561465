import React, { useEffect, useState } from 'react';
import { Tier, TierWrapper } from '../Tier';
import BorderedContainer from '../../../../components/Containers/BorderedContainer';
import logo from '../../../../assets/mint_medical_logo.svg';
import { FilledButton } from '../../../../components/Buttons';
import { useNavigate } from 'react-router-dom';
import { ContactSalesTeamForm } from '../ContactSalesTeamForm/ContactSalesTeamForm';
import { StoreProductDTO, StoreProductTierDTO } from '../../../../types/StoreProduct';
import { DataLoader, LoadingStatus } from '../../../../types/DataLoader';
import { get } from '../../../../libs/Requests';
import { InViewSpinner } from '../../../../components/Spinners/InViewSpinner';
import { Description } from '../../../../components/Description';

interface TiersDataLoader extends DataLoader {
  data?: StoreProductDTO;
}

export function Tiers() {
  const [dataLoader, setDataLoader] = useState<TiersDataLoader>({
    loadingStatus: LoadingStatus.Loading
  });

  const navigator = useNavigate();
  const [contactSalesTeamFormOnScreen, setContactSalesTeamFormOnScreen] = useState(false);
  const [selectedTier, setSelectedTier] = useState<StoreProductTierDTO>();
  const [seats, setSeats] = useState<string>('');
  const [fullPrice, setFullPrice] = useState(0);

  function handleSeatsChange(value: string) {
    setSeats(value);

    const numberedValue = value ? parseInt(value) : 0;

    if (numberedValue === 0) {
      setSelectedTier(undefined);
    } else {
      const newTier = dataLoader.data?.tiers
        .filter((t) => {
          return (
            (!t.seatsRange!.to && numberedValue >= t.seatsRange.from) ||
            (numberedValue >= t.seatsRange.from && numberedValue <= t.seatsRange!.to!)
          );
        })
        .pop();

      if (selectedTier?.id != newTier?.id) {
        setSelectedTier(newTier);
      }

      if (newTier && newTier!.priceByMonth!) {
        setFullPrice(parseInt(newTier!.priceByYear!) * numberedValue);
      }
    }
  }

  function handleContactSalesTeam() {
    setContactSalesTeamFormOnScreen(!contactSalesTeamFormOnScreen);
  }

  function handleCloseContactSalesTeamForm() {
    setContactSalesTeamFormOnScreen(!contactSalesTeamFormOnScreen);
  }

  function handleSubmissionSent() {
    setTimeout(() => {
      setContactSalesTeamFormOnScreen(!contactSalesTeamFormOnScreen);

      handleSeatsChange('');
    }, 2000);
  }

  function handleGetStartedClicked() {
    navigator('/order', {
      state: JSON.stringify({
        productId: 'mintrex',
        selectedTier: selectedTier,
        seats: seats
      })
    });
  }

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    get('/product_tiers?lang=en&product_short_name=mintrex', { signal })
      .then(async (response) => {
        const data = await response!.json();

        setDataLoader({
          ...dataLoader,
          data: data as StoreProductDTO,
          loadingStatus: LoadingStatus.Succeed
        });
      })
      .catch((error) => {
        setDataLoader({
          ...dataLoader,
          error: error.name === 'AbortError' ? undefined : 'Oops, some problems occurred during loading...',
          loadingStatus: LoadingStatus.Failed
        });
      });

    return () => {
      abortController.abort();
    };
  }, []);

  if (dataLoader.loadingStatus != LoadingStatus.Succeed) {
    return (
      <>
        {dataLoader.error || (
          <div className="my-10 flex flex-col">
            <InViewSpinner></InViewSpinner>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <Description>
        <span className="font-semibold">{dataLoader.data?.name}</span>
        {dataLoader.data?.description ? ` - ${dataLoader.data?.description}` : ''}
      </Description>

      <div className="space-y-2">
        {contactSalesTeamFormOnScreen && (
          <ContactSalesTeamForm
            onCloseClick={handleCloseContactSalesTeamForm}
            onSubmissionSent={handleSubmissionSent}></ContactSalesTeamForm>
        )}

        <TierWrapper>
          {dataLoader.data!.tiers.map((t) => (
            <Tier
              label={t.label}
              selected={selectedTier?.id === t.id}
              currency={dataLoader.data!.currency}
              tierContent={t.content}
              key={t.id}
              id={t.id}
              seatsRange={t.seatsRange}
              priceByMonth={t.priceByMonth}></Tier>
          ))}
        </TierWrapper>

        <div className="mx-auto space-y-2 pt-2 md:pt-10">
          <BorderedContainer>
            <div className="">
              <div className="flex flex-col justify-between self-start md:flex-row">
                <img
                  className="w-3/4 self-center py-4 md:w-1/6 md:self-start"
                  src={logo}></img>
                <div
                  className={
                    selectedTier
                      ? 'my-auto flex w-full flex-row justify-between self-start'
                      : 'my-auto hidden w-full flex-row justify-between self-start'
                  }>
                  <span className="pl-0 text-base font-semibold text-mint-medical-grey md:pl-4">
                    {selectedTier?.seatsRange.to ? (
                      <>
                        {selectedTier?.seatsRange.from}-{selectedTier?.seatsRange.to} Users Tier
                      </>
                    ) : (
                      <>{selectedTier?.seatsRange.from}+ Users Tier</>
                    )}
                  </span>
                  <span className={selectedTier?.priceByMonth ? 'text-base font-semibold' : 'hidden'}>
                    {dataLoader.data?.currency === 'eur' && <>&euro;</>}
                    {selectedTier?.priceByMonth?.replace('.00', '')} per user/month
                  </span>
                </div>
              </div>
              <div className="flex flex-row justify-between self-start">
                <span className=" my-auto flex flex-col text-wrap pr-1 text-base font-semibold text-mint-medical-grey md:flex-row">
                  Enter number of users for your plan
                </span>
                <input
                  id="seats"
                  min={0}
                  placeholder="0"
                  autoFocus={true}
                  className="w-12 rounded-md border-2 border-mint-medical-green-lighter p-2 text-center focus:border-mint-medical-green focus:outline-none md:w-24"
                  value={seats}
                  onChange={(event) => {
                    handleSeatsChange(event?.target?.value);
                  }}
                  type="number"></input>
              </div>
              <div className={!selectedTier?.priceByMonth ? 'hidden' : ''}>
                <div className="flex justify-between">
                  <span className="my-auto text-base font-semibold text-mint-medical-grey">Total</span>
                  <span className="text-base font-semibold">
                    {!selectedTier?.priceByMonth ? <>Contact us for pricing</> : ''}
                    {dataLoader.data?.currency === 'eur' && <>&euro;</>}
                    {fullPrice} {dataLoader.data?.currency.toUpperCase()} billed annually
                  </span>
                </div>
              </div>
            </div>
          </BorderedContainer>
          <div className=" flex w-full">
            <div className="mx-auto w-full md:max-w-md">
              <FilledButton
                disabled={!selectedTier}
                onClick={selectedTier?.priceByMonth ? handleGetStartedClicked : handleContactSalesTeam}>
                {!selectedTier || selectedTier?.priceByMonth ? 'Get Started' : 'Contact Sales Team'}
              </FilledButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
