import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { HeadMetadata } from '../../../../components/HeadMetadata';
import { Header, HeaderWrapper } from '../../../../components/Header';
import { confirmSignIn } from '@aws-amplify/auth';
import { FullScreenSpinner } from '../../../OrderForm/components/OrderForm/FullScreenSpinner';
import { SignInState } from '../../types';
import { Description } from '../../../../components/Description';
import {
  SignInConfirmationWithNewPasswordDTO,
  SignInConfirmationWithNewPasswordForm
} from '../../components/SignInConfirmationWithNewPassword/SignInConfirmationWithNewPassword';

export function SignInWithNewPasswordRequiredPage() {
  const [fullScreenSpinnerOnScreen, setFullScreenSpinnerOnScreen] = useState(false);
  const [formError, setFormError] = useState<unknown>('');
  const location = useLocation();
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const { user } = useAuthenticator((context) => {
    return [context.user];
  });

  async function handleSubmit(formData: SignInConfirmationWithNewPasswordDTO) {
    setFullScreenSpinnerOnScreen(true);
    setFormError('');

    try {
      const signInConfirmationResult = await confirmSignIn({
        challengeResponse: formData.password
      });

      setFullScreenSpinnerOnScreen(false);

      if (signInConfirmationResult.nextStep.signInStep === SignInState.DONE) {
        setUserLoggedIn(true);
      }
    } catch (error) {
      setFullScreenSpinnerOnScreen(false);

      showFormError(error);
    }
  }

  function showFormError(error: unknown) {
    const errorText = `${error}`.split('tion: ').pop();

    setFormError(errorText);
  }

  if (userLoggedIn || user) {
    return (
      <Navigate
        to={location.state ? location.state.location : '/account'}
        replace={true}
      />
    );
  }

  return (
    <>
      <HeadMetadata title={'Mint Medical Subscription Store | Sign In With New Passwords'}></HeadMetadata>

      <HeaderWrapper>
        <Header>Welcome to the Mint Medical Subscription Store</Header>
      </HeaderWrapper>

      {fullScreenSpinnerOnScreen && <FullScreenSpinner></FullScreenSpinner>}
      <Description>Set up new passwords.</Description>

      <div className="mx-auto w-full bg-white md:w-2/6">
        <SignInConfirmationWithNewPasswordForm
          submissionError={formError}
          onSubmit={handleSubmit}></SignInConfirmationWithNewPasswordForm>
      </div>
    </>
  );
}
