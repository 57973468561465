import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { HeadMetadata } from '../../../../components/HeadMetadata';
import { Header, HeaderWrapper } from '../../../../components/Header';
import { confirmSignIn } from '@aws-amplify/auth';
import { FullScreenSpinner } from '../../../OrderForm/components/OrderForm/FullScreenSpinner';
import { Description } from '../../../../components/Description';
import { OTPCodeDTO, OTPCodeForm } from '../../components/OTPCodeForm';
import { signInOutputHandler } from '../../utils/signInOutputHandler';

export function SignInCodeConfirmPage() {
  // const [, setCookie] = useCookies(['access_token']);
  const [fullScreenSpinnerOnScreen, setFullScreenSpinnerOnScreen] = useState(false);
  const [formError, setFormError] = useState<unknown>('');
  const location = useLocation();
  const navigator = useNavigate();
  const [userLoggedIn] = useState(false);
  const { user } = useAuthenticator((context) => {
    return [context.user];
  });

  const userEmail = location.state ? JSON.parse(location.state).userEmail : undefined;

  async function handleSubmit(formData: OTPCodeDTO) {
    setFullScreenSpinnerOnScreen(true);
    setFormError('');

    const code = formData.code;

    try {
      const signInConfirmationOutput = await confirmSignIn({
        challengeResponse: code
      });

      if (
        signInConfirmationOutput.nextStep &&
        signInConfirmationOutput.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE' &&
        signInConfirmationOutput.nextStep.additionalInfo?.errorCode === 'WrongCodeException'
      ) {
        throw 'Code not valid.';
      }

      await signInOutputHandler(signInConfirmationOutput, navigator, undefined, location);

      setFullScreenSpinnerOnScreen(false);
    } catch (error) {
      setFullScreenSpinnerOnScreen(false);
      showFormError(error);
    }
  }

  function showFormError(error: unknown) {
    const errorText = `${error}`.split('tion: ').pop();

    setFormError(errorText);
  }

  if (!userEmail) {
    return (
      <Navigate
        to={location.state ? location.state.location : '/auth/sign_in'}
        replace={true}
      />
    );
  }

  if (userLoggedIn || user) {
    return (
      <Navigate
        to={location.state ? location.state.location : '/account'}
        replace={true}
      />
    );
  }

  return (
    <>
      <HeadMetadata title={'Mint Medical Subscription Store | Sign In Code Confirmation'}></HeadMetadata>

      <HeaderWrapper>
        <Header>Welcome to the Mint Medical Subscription Store</Header>
      </HeaderWrapper>

      {fullScreenSpinnerOnScreen && <FullScreenSpinner></FullScreenSpinner>}

      <Description>
        <>
          We have delivered the authentication code by email to <b>{userEmail}</b>. Please enter the code below to
          complete authentication.
        </>
      </Description>

      <div className="mx-auto w-full bg-white md:w-2/6">
        <OTPCodeForm
          submissionError={formError}
          onSubmit={handleSubmit}></OTPCodeForm>
      </div>
    </>
  );
}
