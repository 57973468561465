import { useState, useEffect } from 'react';
import { fetchAuthSession } from '@aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';

export interface UserData {
  email: string;
  groups: string[];
}

enum Status {
  CONFIGURING = 'configuring',
  AUTHORIZED = 'authorized',
  UNAUTHORIZED = 'unauthorized'
}

export function useUserData() {
  const [status, setStatus] = useState<string>(Status.CONFIGURING);
  const [accessToken, setAccessToken] = useState<unknown>(undefined);
  const [idToken, setIdToken] = useState<unknown>(undefined);
  const [userData, setUserData] = useState<UserData | undefined>({
    email: '',
    groups: []
  });

  useEffect(() => {
    return Hub.listen('auth', (data) => {
      console.log('auth');
      console.log(data);

      if (data.payload.event === 'signedOut') {
        setUserData({ email: '', groups: [] });

        setIdToken(undefined);

        setAccessToken(undefined);

        setStatus(Status.UNAUTHORIZED);

        return;
      }

      _setUp();
    });
  }, []);

  useEffect(() => {
    _setUp();

    return () => {
      setStatus(Status.UNAUTHORIZED);

      setUserData(undefined);

      setIdToken(undefined);

      setAccessToken(undefined);
    };
  }, []);

  function _setUp() {
    fetchAuthSession().then((p) => {
      try {
        setStatus(Status.AUTHORIZED);

        const idToken = p.tokens?.idToken;

        const groups = JSON.parse(JSON.stringify(p.tokens?.idToken?.payload))['cognito:groups'] as Array<string>;

        setUserData({ email: idToken?.payload?.email as string, groups });

        setIdToken(idToken);

        setAccessToken(p.tokens?.accessToken);
      } catch (error) {
        console.log(error);

        setUserData({ email: '', groups: [] });

        setIdToken(undefined);

        setAccessToken(undefined);

        setStatus(Status.UNAUTHORIZED);
      }
    });
  }

  return { userData, accessToken, idToken, status };
}
