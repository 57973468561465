import React from 'react';
import { RouteObject } from 'react-router-dom';
import { MainLayout } from '../../../layout/MainLayout';
import { LandingPage } from '../pages/Landing/Landing';

const landingRoutes: RouteObject[] = [
  {
    path: '/',
    element: <MainLayout></MainLayout>,
    children: [
      {
        index: true,
        element: <LandingPage></LandingPage>
      },
      {
        path: 'mintrex',
        element: <LandingPage></LandingPage>
      }
    ]
  }
];

export default landingRoutes;
