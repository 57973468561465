import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import mintMedicalLogo from '../../assets/mint_medical_logo.svg';
import { useEffect, useState } from 'react';
import { Button } from '../Buttons';
import { NavBarItem } from './NavBarItem';
import { signOut } from 'aws-amplify/auth';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useUserAgent } from '../../hooks/useUserAgent';

export function NavBar() {
  const { authStatus } = useAuthenticator((context) => {
    return [context.authStatus];
  });
  const navigate = useNavigate();
  const [menuExpanded, setMenuExpanded] = useState(false);
  const { pathname } = useLocation();
  const { isMintMedicalSoftwareUserAgent } = useUserAgent();

  useEffect(() => {
    if (menuExpanded) {
      setMenuExpanded(!menuExpanded);
    }
  }, [pathname]);

  function handleMenuButtonClicked() {
    setMenuExpanded(!menuExpanded);
  }

  async function handleSignOut() {
    await signOut({
      global: true
    });

    navigate('/auth/sign_in');
  }

  if (isMintMedicalSoftwareUserAgent) {
    return <></>;
  }

  return (
    <nav className=" fixed z-10 h-20 w-full self-center border-b border-solid border-mint-medical-grey-light bg-white p-2">
      <div className="mx-auto flex max-w-screen-xl flex-wrap items-center justify-between py-4">
        <Link
          to="https://mint-medical.com/"
          className="flex items-center space-x-3 rtl:space-x-reverse">
          <img
            src={mintMedicalLogo}
            className=" mb-2"
            alt="Mint Medical Logo"
            width={160}
            height={74}
          />
        </Link>
        <Button
          className="inline-flex h-10 w-10 items-center justify-center rounded-lg p-2 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 md:hidden"
          onClick={handleMenuButtonClicked}>
          <span className="sr-only">Open main menu</span>
          <svg
            className="h-5 w-5"
            aria-hidden="true"
            fill="none"
            viewBox="0 0 17 14">
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </Button>
        <div
          className={menuExpanded ? 'w-full md:block md:w-auto' : 'hidden w-full md:block md:w-auto'}
          id="navbar-default">
          <ul className="mt-4 flex flex-col rounded-lg border border-gray-100 bg-gray-50 p-4 font-medium md:mt-0 md:flex-row md:space-x-8 md:border-0 md:bg-white md:p-0 rtl:space-x-reverse">
            <NavBarItem link="/">mint REX&#8482;</NavBarItem>
            <NavBarItem>mint Lesion&#8482;</NavBarItem>
            <NavBarItem>News</NavBarItem>
            <NavBarItem>Resources</NavBarItem>
            <NavBarItem>Company</NavBarItem>
            <NavBarItem>Demo</NavBarItem>
            {authStatus !== 'authenticated' ? (
              <NavBarItem link="/auth/sign_in">Sign in</NavBarItem>
            ) : pathname !== '/account' ? (
              <NavBarItem link="/account">Account</NavBarItem>
            ) : (
              <NavBarItem onClick={handleSignOut}>Sign Out</NavBarItem>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}
