import React, { useEffect, useState } from 'react';
import { Header, HeaderWrapper } from '../../../../components/Header';
import { Description } from '../../../../components/Description';
import { HeadMetadata } from '../../../../components/HeadMetadata';
import { CompanyAccountDetails } from '../CompanyAccountDetails/CompanyAccountDetails';
import { ICompanyDomain } from '../../../../types/CompanyDomain';
import { CompanyPurchaseHistory } from '../CompanyPurchaseHistory/CompanyPurchaseHistory';
import { CompanyLicenses } from '../CompanyLicenses/CompanyLicenses';
// import { JwtPayload } from '@aws-amplify/core/internals/utils';
import { get } from '../../../../libs/Requests';
import { FullScreenSpinner } from '../../../OrderForm/components/OrderForm/FullScreenSpinner';
import { CompanyOrderedLicenses } from '../../../CompanyOrderedLicenses/CompanyOrderedLicenses';

import i18next from '../../../../i18n';

export interface CompanyManagerAccountProps {
  userEmail: string | undefined;
}

interface CompanyData {
  status: string;
  id: string;
  name: string;
  manager?: {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
  };
  domains: ICompanyDomain[];
}

enum TabsSwitcherState {
  AccountDetails,
  ManageLicenses,
  PurchaseHistory
}

export function CompanyManagerAccount(props: CompanyManagerAccountProps) {
  const [tabSelected, setTabSelected] = useState(TabsSwitcherState.ManageLicenses);
  const [companyData, setCompanyData] = useState<CompanyData>();

  function handleAccountDetailsTabClicked() {
    if (tabSelected !== TabsSwitcherState.AccountDetails) {
      setTabSelected(TabsSwitcherState.AccountDetails);
    }
  }

  function handleManageLicensesTabClicked() {
    if (tabSelected !== TabsSwitcherState.ManageLicenses) {
      setTabSelected(TabsSwitcherState.ManageLicenses);
    }
  }

  function handlePurchaseHistoryTabClicked() {
    if (tabSelected !== TabsSwitcherState.PurchaseHistory) {
      setTabSelected(TabsSwitcherState.PurchaseHistory);
    }
  }

  async function getCompanyData() {
    if (!companyData) {
      const companyResponse = await get('/company');

      setCompanyData(await companyResponse.json());
    }
  }

  useEffect(() => {
    getCompanyData();
  }, [companyData]);

  if (!companyData) {
    return (
      <>
        <HeadMetadata title={'Mint Medical Subscription Store | Account'}></HeadMetadata>

        <HeaderWrapper>
          <Header>Account</Header>
        </HeaderWrapper>
        <FullScreenSpinner></FullScreenSpinner>
      </>
    );
  }

  if (companyData?.status === 'Pending') {
    return (
      <>
        <HeadMetadata title={'Mint Medical Subscription Store | Account'}></HeadMetadata>

        <HeaderWrapper>
          <Header>{i18next.t('org_admin.account_under_review.title')}</Header>
        </HeaderWrapper>

        <Description>
          <div>{i18next.t('org_admin.account_under_review.description')}</div>
        </Description>

        <div className="flex max-h-fit w-full max-w-screen-xl flex-col bg-white py-4 md:py-8">
          <div className="text-justify text-xl text-[#282829] md:text-center md:text-2xl">
            Email about approval will be sent to:
          </div>
          <div className="text-justify text-xl font-semibold text-[#282829] md:text-center md:text-2xl">
            {props.userEmail ? `${props.userEmail}` : ''}
          </div>
        </div>

        <CompanyOrderedLicenses></CompanyOrderedLicenses>
      </>
    );
  }

  if (companyData?.status === 'Denied') {
    return (
      <>
        <HeadMetadata title={'Mint Medical Subscription Store | Account'}></HeadMetadata>

        <HeaderWrapper>
          <Header>{i18next.t('org_admin.account_denied.title')}</Header>
        </HeaderWrapper>
        <Description>
          <>{i18next.t('org_admin.account_denied.description')}</>
        </Description>
      </>
    );
  }

  return (
    <>
      <HeadMetadata title={'Mint Medical Subscription Store | Account'}></HeadMetadata>

      <HeaderWrapper>
        <Header>Account Management</Header>
      </HeaderWrapper>

      <TabSwitcher>
        <Tab
          onClick={handleAccountDetailsTabClicked}
          selected={tabSelected === TabsSwitcherState.AccountDetails}>
          Account Details
        </Tab>
        <Tab
          onClick={handleManageLicensesTabClicked}
          selected={tabSelected === TabsSwitcherState.ManageLicenses}>
          Manage Licenses
        </Tab>
        <Tab
          onClick={handlePurchaseHistoryTabClicked}
          selected={tabSelected === TabsSwitcherState.PurchaseHistory}>
          Purchase History
        </Tab>
      </TabSwitcher>

      <hr className="h-px border-0 bg-mint-medical-grey-lighter"></hr>

      {tabSelected === TabsSwitcherState.AccountDetails && companyData && (
        <CompanyAccountDetails
          companyData={companyData!}
          companyDataUpdated={() => setCompanyData(undefined)}></CompanyAccountDetails>
      )}

      {tabSelected === TabsSwitcherState.ManageLicenses && companyData && <CompanyLicenses></CompanyLicenses>}

      {tabSelected === TabsSwitcherState.PurchaseHistory && companyData && (
        <CompanyPurchaseHistory></CompanyPurchaseHistory>
      )}
    </>
  );
}

export interface TabSwitcherProps {
  children: React.ReactNode;
}

export function TabSwitcher(props: TabSwitcherProps) {
  return (
    <div className="flex flex-row justify-between py-4 text-mint-medical-grey md:mx-auto md:max-w-screen-md md:space-x-8">
      {props.children}
    </div>
  );
}

export interface TabProps {
  children: React.ReactNode;
  onClick: () => void;
  selected: boolean;
}

export function Tab(props: TabProps) {
  return (
    <div
      className={
        props.selected
          ? 'cursor-pointer text-xs font-bold text-mint-medical-green md:text-lg'
          : 'cursor-pointer text-xs font-bold md:text-lg'
      }
      onClick={props.onClick}>
      {props.children}
    </div>
  );
}
