import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { MainLayout } from '../../../layout/MainLayout';
import { NotFoundPage } from '../pages/NotFound/NotFound';

const notFoundRoutes: RouteObject[] = [
  {
    path: '/',
    element: <MainLayout></MainLayout>,
    children: [
      {
        path: 'not_found',
        element: <NotFoundPage></NotFoundPage>
      },
      {
        path: '*',
        element: <Navigate to="/not_found"></Navigate>
      }
    ]
  }
];

export default notFoundRoutes;
