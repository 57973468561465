import React, { useState } from 'react';
import { ICompanyDomain } from '../../../../types/CompanyDomain';
import { EStatus } from '../../../../types/Status';
import { DeleteDomainConfirm } from '../CompanyDomains/DeleteDomainConfirm';
import {
  ConfirmationActions,
  ConfirmationModal
} from '../../../mint_admin_account/components/CompanyStatusUpdater/ConfirmationModal';
import { PendingDot } from '../../../../components/Dots/PendingDot';
import { ApprovedDot } from '../../../../components/Dots/ApprovedDot';
import { DeniedDot } from '../../../../components/Dots/DeniedDot';

export interface CompanyDomainComponentProps {
  onDelete?: (domainName: string) => void;
  onStatusChange?: (domainName: string, status: string) => void;
  domain: ICompanyDomain;
  showDeleteOption?: boolean;
  blockEditingStatus?: boolean;
}

enum CompanyDomainStatus {
  Approved = 'Approved',
  Denied = 'Denied'
}

interface ConfirmationData {
  action: string;
  yesLabel: string;
  mainColor: string;
  yesAction: () => void;
}

export function CompanyDomainComponent(props: CompanyDomainComponentProps) {
  const [deleteDomainFormOnScreen, setDeleteDomainFormOnScreen] = useState(false);
  const [confirmationData, setConfirmationData] = useState<ConfirmationData | undefined>();

  const editingStatusDisabled = !props.showDeleteOption || props.blockEditingStatus;

  function fireConfirmationModal(action: CompanyDomainStatus) {
    setConfirmationData({
      action: action,
      yesLabel: action === CompanyDomainStatus.Approved ? 'Yes, Approve' : 'Yes, Deny',
      mainColor: action === CompanyDomainStatus.Approved ? 'mint-medical-green' : 'red-500',
      yesAction: async () => {
        props.onStatusChange && (await props.onStatusChange(props.domain.domainName, action));

        setConfirmationData(undefined);
      }
    });
  }

  function handleDeleteClick() {
    setDeleteDomainFormOnScreen(!deleteDomainFormOnScreen);
  }

  async function handleDeleteConfirmation() {
    try {
      setDeleteDomainFormOnScreen(!deleteDomainFormOnScreen);

      props.onDelete && props.onDelete(props.domain.domainName);
    } catch (error) {
      console.error(error);
    }
  }

  async function handleStatusChange(value: string) {
    fireConfirmationModal(value === 'Approved' ? CompanyDomainStatus.Approved : CompanyDomainStatus.Denied);
  }

  return (
    <>
      <DeleteDomainConfirm
        yesMethod={handleDeleteConfirmation}
        domainName={props.domain.domainName}
        visible={deleteDomainFormOnScreen}
        closeModal={handleDeleteClick}></DeleteDomainConfirm>
      {confirmationData && (
        <ConfirmationModal
          action={
            confirmationData.action === CompanyDomainStatus.Approved
              ? ConfirmationActions.Approve
              : ConfirmationActions.Deny
          }
          title={'Confirmation'}
          visible={!!confirmationData}
          yesLabel={confirmationData.yesLabel}
          mainColor={confirmationData.mainColor}
          closeModal={() => {
            setConfirmationData(undefined);
          }}
          yesMethod={confirmationData.yesAction}>
          Are you sure you want to {confirmationData.action === CompanyDomainStatus.Approved ? 'approve' : 'deny'}{' '}
          domain <span className=" font-semibold">{props.domain.domainName}</span>?
        </ConfirmationModal>
      )}
      <div className="flex w-full flex-row justify-end px-4 py-4">
        <div className="my-auto w-1/5 md:w-2/5">
          <div>
            <div className="my-auto flex flex-row">
              {props.domain.status === EStatus.Pending && <PendingDot></PendingDot>}
              {props.domain.status === EStatus.Approved && <ApprovedDot></ApprovedDot>}
              {props.domain.status === EStatus.Denied && <DeniedDot></DeniedDot>}
              <select
                value={props.domain.status}
                onChange={(event) => {
                  handleStatusChange(event.target.value);
                }}
                className=" hidden cursor-pointer text-wrap pl-2 text-center text-end outline-none disabled:appearance-none md:inline"
                disabled={editingStatusDisabled}>
                <option
                  disabled={props.domain.status === EStatus.Denied || props.domain.status === EStatus.Approved}
                  value={EStatus.Pending}>
                  Pending Approval
                </option>
                <option value={EStatus.Approved}>Approved</option>
                <option value={EStatus.Denied}>Denied</option>
              </select>
            </div>
          </div>
        </div>
        <div className="my-auto w-full md:w-2/5">{props.domain.domainName}</div>
        <div className="my-auto flex self-end">
          <button
            className={
              props.showDeleteOption ? 'my-auto self-end font-semibold text-red-500 hover:underline' : ' invisible'
            }
            onClick={handleDeleteClick}>
            Remove
          </button>
        </div>
      </div>
    </>
  );
}
