import React, { useState } from 'react';
import BorderedContainer from '../../../../components/Containers/BorderedContainer';
import logo from '../../../../assets/mint_medical_logo.svg';
import { CompanyLicenseUsers } from '../CompanyLicenseUsers/CompanyLicenseUsers';
import { Toggle } from '../../../../components/Toggle/Toggle';
import { post } from '../../../../libs/Requests';
import { useProductData } from '../../../../hooks/useProductData';

interface CompanyLicenseProps {
  subscription: License;
}

export interface License {
  productId: string;
  cancel_at: number;
  cancel_at_period_end: boolean;
  current_period_start: number;
  current_period_end: number;
  id: string;
  seats: number;
  status: string;
  start_date: number;
  type: string;
}

export function CompanyLicense(props: CompanyLicenseProps) {
  const { productData, getTierName } = useProductData({
    productId: props.subscription.productId,
    lang: 'en'
  });

  const [subscription, setSubscription] = useState<License>(props.subscription);
  const [licenseEditMode, setLicenseEditMode] = useState(false);
  const [autoRenewable, setAutoRenewable] = useState(!props.subscription.cancel_at_period_end);
  const [licenseNumber, setLicenseNumber] = useState(0);
  const [numberOfLicenseUsers, setNumberOfLicenseUsers] = useState(0);

  async function handleEditClick() {
    try {
      if (licenseEditMode) {
        const subDiff = {
          cancel_at_period_end: !autoRenewable,
          licenses_number: licenseNumber
        };

        const sub = await post(`/company/subscription/${props.subscription.id}/`, JSON.stringify(subDiff));

        setSubscription(await sub.json());
      }

      setLicenseEditMode(!licenseEditMode);

      setLicenseNumber(subscription.seats);
    } catch (error) {
      console.error(error);
    }
  }

  function handleToggle(checked: boolean) {
    setAutoRenewable(checked);
  }

  return (
    <div className="mb-2 mt-2">
      <BorderedContainer key={props.subscription.id}>
        <div className="flex flex-col md:flex-row-reverse">
          <div className=" flex flex-col">
            <span className="font-semibold">License Number:</span>
            <span className="font-semibold">{props.subscription.id.replace('sub_', '')}</span>
          </div>

          <div className="flex flex-col gap-4 md:w-full md:flex-row">
            <div className="w-full md:w-1/6">
              <img
                // className="w-full md:w-1/12"
                src={logo}></img>
            </div>

            <div className="flex flex-col">
              <span className="font-semibold">
                {productData?.name ? productData.name : ''} |{' '}
                {subscription.seats ? getTierName(subscription.seats) : ''}
              </span>
              <span>
                {subscription.seats} Seat{subscription.seats > 1 ? 's' : ''}, Billed annually
              </span>
              <div className="flex flex-row space-x-1">
                <span>Subscription status:</span>
                <span
                  className={
                    subscription.status?.toLowerCase() === 'active' ? ' text-mint-medical-green' : ' text-red-500'
                  }>
                  {subscription.status.toUpperCase()}
                </span>
              </div>
              <span>Purchase Date: {new Date(subscription.start_date * 1000).toLocaleDateString()}</span>
              <div>
                <span className="text-[#D81818]">
                  {subscription.cancel_at ? (
                    subscription.cancel_at * 1000 > Date.now() ? (
                      <>Ends on {new Date(subscription.cancel_at * 1000).toLocaleDateString()}</>
                    ) : (
                      <>Ended on {new Date(subscription.cancel_at * 1000).toLocaleDateString()}</>
                    )
                  ) : (
                    <>Auto renews on {new Date(subscription.current_period_end * 1000).toLocaleDateString()}</>
                  )}
                </span>
                <button
                  className={subscription.type === 'AutoRenewal' ? 'pl-4 text-mint-medical-green' : 'hidden'}
                  onClick={handleEditClick}>
                  {licenseEditMode ? 'Done' : 'Edit'}
                </button>
                {licenseEditMode && (
                  <div className="flex flex-col py-2">
                    <Toggle
                      checked={!subscription.cancel_at_period_end}
                      onChange={handleToggle}></Toggle>
                    <div className=" inline-flex pt-2">
                      <input
                        className="h-9 w-11 rounded-md border border-2 border-mint-medical-green bg-mint-medical-green-light text-center outline-none"
                        value={licenseNumber}
                        disabled={true}></input>

                      <div className="my-auto ms-3 text-sm font-medium text-gray-900">
                        <span>Seats</span>
                        <input
                          className="ms-3 h-9 w-9 cursor-pointer rounded-full border-2 border-mint-medical-green bg-mint-medical-green-lighter disabled:border"
                          type="button"
                          disabled={licenseNumber === subscription.seats}
                          onClick={() => {
                            if (licenseNumber - 1 >= subscription.seats) {
                              setLicenseNumber(licenseNumber - 1);
                            }
                          }}
                          value="-"></input>
                        <input
                          className="ms-3 h-9 w-9 cursor-pointer rounded-full border border-2 border-mint-medical-green bg-mint-medical-green-lighter"
                          type="button"
                          onClick={() => {
                            setLicenseNumber(licenseNumber + 1);
                          }}
                          value="+"></input>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <span className="italic">
                {numberOfLicenseUsers}/{subscription.seats} users activated
              </span>
            </div>
          </div>
        </div>

        <div className="bg-red my-4 h-px w-full bg-mint-medical-grey-light"></div>

        <CompanyLicenseUsers
          isLicenseActive={props.subscription.status.toLocaleLowerCase() === 'active'}
          licenseId={props.subscription.id}
          onLicensedUsersNumberUpdated={(num) => {
            setNumberOfLicenseUsers(num);
          }}></CompanyLicenseUsers>
      </BorderedContainer>
    </div>
  );
}
